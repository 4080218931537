/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect, useCallback } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import {
  Typography,
  Spacer,
  Button,
  Flex,
  Card,
  LoadingBar,
  Toast,
} from "ingred-ui";
import { Author, Tag } from "../../types";
import { AuthContext } from "../../auth";
import {
  getTagsByParams,
  getTagsByUser,
  createTag,
  getAuthor,
} from "../../client";
import { StorageKey } from "../../constants/storageKeys";
import { Breadcrumbs } from "../../components/Breadcrumbs";
import { ExternalizeStateDataTable } from "../../components/ExternalizeStateDataTable";
import { CreateModal } from "./internal/CreateModal";
import { Header } from "../../components/Header";
import firebase from "firebase/app";
import moment from "moment";
import copy from "copy-to-clipboard";
import * as Styled from "./styled";
import { DemoLinksSideBar } from "../../components/Sidebar";

type FetchTagParams = {
  sortKey: string;
  sortOrder: "asc" | "desc";
  filter: string;
  per: number;
};

export const List: React.FunctionComponent = () => {
  const tagListCache = localStorage.getItem(StorageKey.TAG_LIST_CACHE);
  const [fetchTagParams, setFetchTagParams] = useState<FetchTagParams>(
    tagListCache
      ? JSON.parse(tagListCache)
      : {
          sortKey: "updated",
          sortOrder: "desc",
          filter: "mine",
          per: 10,
        },
  );
  const [cursor, setCursor] =
    useState<
      firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
    >();
  const [pastTagList, setPastTagList] = useState<Tag[][]>([]);
  const [pastTagListCursor, setPastTagListCursor] = useState<number>(0);
  const [tags, setTags] = useState<Tag[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [createLoading, setCreateLoading] = useState<boolean>(false);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [selectedTagsURL, setSelectedTagsURL] = useState<string>("");
  const [isLastPage, setIsLastPage] = useState<boolean>(false);
  const [isGottenLastPage, setIsGottenLastPage] = useState<boolean>(false);
  const [listSize, setListSize] = useState<number>(0);

  const auth = useContext(AuthContext);
  const navigate = useNavigate();
  const { addToast } = Toast.useToasts();

  const tabs = [
    {
      label: "自分の作成したタグ",
      externalValue: "mine",
    },
    {
      label: "全て",
      externalValue: "all",
    },
  ];

  const getTags = useCallback(
    async (
      pastTagList: Tag[][],
      fetchTagParams: FetchTagParams,
      listSize: number,
      cursor?: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>,
    ) => {
      if (auth.firebaseUser === null) {
        // eslint-disable-next-line no-console
        console.error("userがいません");
        return;
      }
      setLoading(true);
      const resp =
        fetchTagParams.filter === "mine"
          ? await getTagsByUser(
              auth.firebaseUser.uid,
              fetchTagParams.sortKey,
              fetchTagParams.sortOrder,
              fetchTagParams.per,
              cursor,
            )
          : await getTagsByParams(
              fetchTagParams.sortKey,
              fetchTagParams.sortOrder,
              fetchTagParams.per,
              cursor,
            );
      if (resp === undefined) {
        return;
      }

      const nextResp =
        fetchTagParams.filter === "mine"
          ? await getTagsByUser(
              auth.firebaseUser.uid,
              fetchTagParams.sortKey,
              fetchTagParams.sortOrder,
              fetchTagParams.per,
              resp.docs.slice(-1)[0],
            )
          : await getTagsByParams(
              fetchTagParams.sortKey,
              fetchTagParams.sortOrder,
              fetchTagParams.per,
              resp.docs.slice(-1)[0],
            );
      if (nextResp === undefined || !nextResp.docs.length) {
        setIsLastPage(true);
        setIsGottenLastPage(true);
      }

      setCursor(resp.docs.slice(-1)[0]);

      const tags: Tag[] = await Promise.all(
        resp.docs.map(async (doc) => {
          const tag = {
            ...doc.data(),
            created: doc.data().created.toDate(),
            updated: doc.data().updated.toDate(),
            tagId: doc.id,
          } as Tag;
          const author = await getAuthor(tag.authorRef.id);
          tag.createdBy = author.data() as Author;
          return tag;
        }),
      );

      let tagNumber = 0;
      for (const tag of tags) {
        tag.id = tagNumber;
        tagNumber++;
      }

      pastTagList.push(tags);
      setPastTagList(pastTagList);

      const nextListSize = listSize + 1;
      setListSize(nextListSize);

      setTags(tags);
      setLoading(false);
    },
    [auth.firebaseUser],
  );

  const onHandlePerChange = (per: number) => {
    const newParams = { ...fetchTagParams, per };
    setFetchTagParams(newParams);
  };

  const onHandleColumnChange = (value: string) => {
    let newParams: FetchTagParams;
    if (fetchTagParams.sortKey !== value) {
      newParams = { ...fetchTagParams, sortKey: value, sortOrder: "desc" };
    } else {
      if (fetchTagParams.sortOrder === "desc") {
        newParams = { ...fetchTagParams, sortOrder: "asc" };
      } else {
        newParams = { ...fetchTagParams, sortOrder: "desc" };
      }
    }
    setFetchTagParams(newParams);
  };

  const onHandleTabChange = (filter: string) => {
    const newParams = { ...fetchTagParams, filter };
    setFetchTagParams(newParams);
  };

  const onHandlePagerChange = (direction: "prev" | "next") => {
    const pastTagListSize = pastTagList.length - 1;
    if (direction === "next") {
      if (pastTagListCursor) {
        // キャッシュの存在するページの場合はキャッシュした内容を表示
        const nextPageIndex = pastTagListSize - pastTagListCursor + 1;
        if (listSize - 1 === nextPageIndex && isGottenLastPage) {
          setIsLastPage(true);
        }
        setTags(pastTagList[nextPageIndex]);
        setPastTagListCursor(pastTagListCursor - 1);
      } else {
        getTags(pastTagList, fetchTagParams, listSize, cursor);
      }
    } else {
      const prevPageIndex = pastTagListSize - pastTagListCursor - 1;
      setTags(pastTagList[prevPageIndex]);
      setPastTagListCursor(pastTagListCursor + 1);
      setIsLastPage(false);
    }
  };

  const handleChangeCreateModalOpen = (isOpen: boolean) => () =>
    setCreateModalOpen(isOpen);

  const handleCreate = async ({
    body,
    title,
    memo,
  }: Pick<Tag, "title" | "body" | "memo">) => {
    const user = firebase.auth().currentUser;
    setCreateLoading(true);
    const resp = await createTag(body, title, memo, user?.uid).catch(() => {
      addToast("タグの追加に失敗しました。", {
        appearance: "error",
        autoDismiss: true,
      });
    });
    setCreateLoading(false);
    if (resp) navigate(`/tag/${resp.id}`);
  };

  const handleCopyToClipboard = () => {
    copy(selectedTagsURL);
    addToast("選択したタグをクリップボードにコピーしました。", {
      appearance: "success",
      autoDismiss: true,
    });
  };

  const url = window.location.href.replace("/tags", "/tag");

  useEffect(() => {
    localStorage.setItem(
      StorageKey.TAG_LIST_CACHE,
      JSON.stringify(fetchTagParams),
    );
    // 検索条件の変更にあわせてページング状態をリセット
    setPastTagListCursor(0);
    setIsLastPage(false);
    setIsGottenLastPage(false);

    getTags([], fetchTagParams, 0);
  }, [fetchTagParams, auth.firebaseUser, getTags]);

  useEffect(() => {
    let tagsURL = "";
    for (const row of selectedRows) {
      const tag = tags.find((tag) => tag.id === row);
      if (tag) {
        tagsURL += `${tag.title}: ${url}/${tag.tagId}\n`;
      }
    }
    setSelectedTagsURL(tagsURL);
  }, [selectedRows, setSelectedTagsURL, tags, url]);

  if (!auth.loggedIn) {
    return <Navigate to={"/signin"} />;
  }

  return (
    <Styled.Container>
      <Header />
      <Spacer pt={1} />
      <Spacer p={1}>
        <Spacer pl={2} pb={2} pt={1}>
          <Breadcrumbs
            items={[
              {
                title: "タグ一覧",
              },
            ]}
          />
        </Spacer>
        <Flex display="flex" alignItems="flex-start">
          <Styled.ListContainer>
            <Styled.TitleContainer>
              <Spacer p={3}>
                <Typography weight="bold" size="xl">
                  タグ一覧
                </Typography>
              </Spacer>
            </Styled.TitleContainer>

            <Spacer pt={1}>
              {loading ? (
                <LoadingBar />
              ) : (
                <Spacer p={2}>
                  <ExternalizeStateDataTable
                    per={fetchTagParams.per}
                    isFirstPage={pastTagList.length - pastTagListCursor === 1}
                    isLastPage={isLastPage}
                    enablePagination={true}
                    verticalSpacing="large"
                    data={tags}
                    tabs={tabs}
                    tabIndex={
                      tabs.findIndex(
                        (tab) => tab.externalValue === fetchTagParams.filter,
                      ) | 0
                    }
                    columns={[
                      {
                        name: "タイトル",
                        value: "title",
                        selector: (data) => data.title,
                        renderCell: (data) => (
                          <Styled.LinkContainer to={`/tag/${data.tagId}`}>
                            <Typography color="primary">
                              {data.title}
                            </Typography>
                          </Styled.LinkContainer>
                        ),
                        sortable: true,
                      },
                      {
                        name: "更新日時",
                        value: "updated",
                        selector: (data) =>
                          moment(data.updated).format("YYYY/MM/DD HH:mm"),
                        sortable: true,
                      },
                      {
                        name: "作成日時",
                        value: "created",
                        selector: (data) =>
                          moment(data.created).format("YYYY/MM/DD HH:mm"),
                        sortable: true,
                      },
                      {
                        name: "作成者",
                        value: "email",
                        selector: (data) => data.createdBy.email,
                      },
                    ]}
                    activeColumn={fetchTagParams.sortKey}
                    order={fetchTagParams.sortOrder}
                    onHandlePagerChange={onHandlePagerChange}
                    onHandlePerChange={onHandlePerChange}
                    onHandleTabChange={onHandleTabChange}
                    onHandleColumnChange={onHandleColumnChange}
                    onSelectRowsChange={setSelectedRows}
                  />
                </Spacer>
              )}
            </Spacer>
          </Styled.ListContainer>

          <Styled.StickyContainer>
            <Card ml={3} p={3} minWidth="280px">
              <Flex display="flex" justifyContent="center">
                {selectedRows.length ? (
                  <Button
                    size="large"
                    inline={true}
                    onClick={handleCopyToClipboard}
                  >
                    選択したタグを
                    <br />
                    クリップボードにコピー
                  </Button>
                ) : (
                  <Button
                    size="large"
                    inline={true}
                    onClick={handleChangeCreateModalOpen(true)}
                  >
                    タグの新規作成
                  </Button>
                )}
                {createModalOpen && (
                  <CreateModal
                    loading={createLoading}
                    onClose={handleChangeCreateModalOpen(false)}
                    onSubmit={handleCreate}
                  />
                )}
              </Flex>
            </Card>
            <Spacer pt={2} />
            <DemoLinksSideBar />
          </Styled.StickyContainer>
        </Flex>
      </Spacer>
    </Styled.Container>
  );
};
