import React from "react";

import { Select } from "ingred-ui";
import { OptionType } from "ingred-ui/dist/components/Select/Select";

const defaultOptions: OptionType[] = [
  {
    label: "10件",
    value: "10",
  },
  {
    label: "25件",
    value: "25",
  },
  {
    label: "50件",
    value: "50",
  },
  {
    label: "100件",
    value: "100",
  },
  {
    label: "500件",
    value: "500",
  },
  {
    label: "1000件",
    value: "1000",
  },
];

export type Props = {
  per: number;
  onChange: (per: number) => void;
  options?: OptionType[];
};

const CountChanger: React.FunctionComponent<Props> = ({
  per,
  onChange,
  options = defaultOptions,
}) => {
  const handleChange = (selectedOption: any) => {
    const option: OptionType = selectedOption || options[0];
    onChange(parseInt(option.value));
  };

  return (
    <Select
      isSearchable={false}
      name="count_changer"
      defaultValue={{ value: per.toString(), label: `${per}件` }}
      options={options}
      isClearable={false}
      minWidth={"98px"}
      onChange={handleChange}
    />
  );
};

export { CountChanger };
