import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import { config } from "./config";

firebase.initializeApp(config);

export const db = firebase.firestore();

// NOTE(suzuken): ローカル環境の場合にはFirebaseエミュレータにつなぎます。
// refs https://github.com/voyagegroup/fluct-tagviewer/issues/27
//
// そのため、エミュレータを立ち上げていないとローカルでは確認できません。
if (window.location.hostname === "localhost") {
  db.settings({
    host: "localhost:8023",
    ssl: false,
  });
}

export default firebase;
