import React, { useState, useEffect, useContext } from "react";
import { GlobalStyle } from "./globalStyle";
import { ThemeProvider, createTheme, Toast } from "ingred-ui";
import { Routes, Route, Navigate, RouteProps } from "react-router-dom";
import { AuthContext } from "./auth";
import { List } from "./pages/List";
import { Detail } from "./pages/Detail";
import { SignIn } from "./pages/SignIn";
import { Preview } from "./pages/Preview";
import { AMPPreview } from "./pages/AMPPreview";
import { NotFound } from "./pages/NotFound";
import { SaveTagsFromMs } from "./pages/SaveTagsFromMs";
import { Landing } from "./pages/Landing";
import {
  Billboard,
  Gate,
  Impress,
  Rectangle,
  SurfacePc,
  SurfaceSp,
} from "./pages/Demo";
import { Loading } from "./components/Loading";
import { RenderMultiRoutes } from "./components/utils/RenderMultiRoutes";
import firebase from "firebase/app";
import "ace-builds"; // ace-editorのwebpack解決
import "ace-builds/webpack-resolver";

const App = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const auth = useContext(AuthContext);
  const theme = createTheme();

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        auth.loggedIn = true;
        auth.firebaseUser = user;
      }
      setLoading(false);
    });
  }, [auth]);

  const PrivateRoute: React.FC<RouteProps> = ({ children }) => {
    return auth.loggedIn ? (
      <div>{children}</div>
    ) : (
      <Navigate
        to={{
          pathname: "/signin",
        }}
        state={{ from: location }}
      />
    );
  };

  return (
    <AuthContext.Provider value={auth}>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <Toast.Provider
          placement="top-center"
          autoDismissTimeout={3000}
          transitionDuration={300}
        >
          {loading ? (
            <Loading />
          ) : (
            <Routes>
              <Route path="/tag/:id" element={<Detail />} />
              <Route path="/preview/:id?" element={<Preview />} />
              <Route path="/ampPreview/:id?" element={<AMPPreview />} />
              <Route
                path="/SaveTagsFromMs/:ampOrColumn?"
                element={<SaveTagsFromMs />}
              />
              <Route path="/landing" element={<Landing />} />
              <Route path="/signin" element={<SignIn />} />
              <Route path="/demo/surface_pc" element={<SurfacePc />} />
              <Route path="/demo/surface_sp" element={<SurfaceSp />} />
              <Route path="/demo/impress" element={<Impress />} />
              <Route path="/demo/gate" element={<Gate />} />
              <Route path="/demo/rectangle" element={<Rectangle />} />
              <Route path="/demo/billboard" element={<Billboard />} />
              {RenderMultiRoutes({
                paths: ["/", "/tags"],
                element: <PrivateRoute>{<List />}</PrivateRoute>,
              })}
              <Route path={`*`} element={<NotFound />} />
            </Routes>
          )}
        </Toast.Provider>
      </ThemeProvider>
    </AuthContext.Provider>
  );
};

export default App;
