import styled from "styled-components";
import { Link } from "react-router-dom";

export const Container = styled.div`
  text-align: center;
`;
export const TitleContainer = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.palette.gray.light};
`;

export const ListContainer = styled.div`
  background: ${({ theme }) => theme.palette.white};
  border-radius: 8px;
  width: 85%;
`;

export const LinkContainer = styled(Link)`
  text-decoration: none;
  :hover {
    text-decoration: underline;
    text-decoration-color: ${({ theme }) => theme.palette.text.primary};
  }
`;

export const StickyContainer = styled.div`
  position: sticky;
  top: ${({ theme }) => theme.spacing * 3}px;
`;
