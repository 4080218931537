/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable @typescript-eslint/no-require-imports */
import React, { useState, useEffect, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Spacer, Typography, Button } from "ingred-ui";
import { getTag } from "../../client";
import { StorageKey } from "../../constants/storageKeys";
import { Header } from "../../components/Header";
import { Loading } from "../../components/Loading";
import { Tag } from "../../types";
import * as Styled from "./styled";

export const Preview: React.FunctionComponent = () => {
  const { id } = useParams<{ id: string }>();
  const [loading, setLoading] = useState<boolean>(false);
  const [tagNotFound, setTagNotFound] = useState<boolean>(false);
  const [tagBody, setTagBody] = useState<string>();
  const [isFjs, setIsFjs] = useState<boolean>(false);
  const navigate = useNavigate();

  const fetchTag = useCallback(async () => {
    setLoading(true);
    if (id === undefined) {
      return false;
    }
    const doc = await getTag(id);
    if (doc.data()) {
      const tag: Tag = {
        ...doc.data(),
      } as Tag;

      setTagBody(tag.body);
      setLoading(false);
    } else {
      setTagNotFound(true);
    }
  }, [id]);

  useEffect(() => {
    if (id) {
      fetchTag();
    } else if (localStorage.getItem(StorageKey.TAG_BODY_BUFFER)) {
      setTagBody(localStorage.getItem(StorageKey.TAG_BODY_BUFFER) as string);
    }
  }, [id, fetchTag]);

  useEffect(() => {
    // インターステイシャル広告の表示期間制限を初期化
    if (localStorage.getItem(StorageKey.INST_BUFFER)) {
      localStorage.removeItem(StorageKey.INST_BUFFER);
    }

    if (tagBody) {
      const postscribe = require("postscribe");
      if (tagBody.includes("f.js?G=")) {
        setIsFjs(true);
      }
      postscribe("#sync-tag", tagBody);
    }
  }, [tagBody]);

  if (tagNotFound) {
    navigate("/notfound");
    return null;
  } else if (!tagBody) {
    return <div>{loading && <Loading />}</div>;
  }

  return (
    <Styled.Container>
      <Header loginAvailable={false} />

      <Spacer p={5}>
        <Typography lineHeight="1.7" size="xxl">
          このページはプレビューページです。
        </Typography>
        <Typography lineHeight="1.7" weight="bold" size="xxl">
          スマートフォン用広告は、PCビューからは見ることができない場合があります。
          その場合はPCブラウザのUA（User
          Agent）をスマートフォンにして確認するか、
          <br />
          実際のスマートフォンにてのご確認をお願いいたします。
        </Typography>
        <Typography lineHeight="1.7" size="xxl">
          タグの内容を更新した場合、この画面を閉じてからもう一度
          「プレビューを表示」ボタンをクリックして再度ご確認ください。
        </Typography>
        <Typography lineHeight="1.7" size="xxl">
          タグが表示されない場合は、何度かページリロードを行ってみてください。
        </Typography>
        <Typography lineHeight="1.7" size="xxl">
          インタースティシャル広告の表示確認には以下のリンクを利用してください。
        </Typography>
        <Spacer p={5}>
          <Styled.LinkButtonContainer>
            <Button inline href="/landing" color="secondary" size="large">
              インステ動作確認用リンク
            </Button>
          </Styled.LinkButtonContainer>
        </Spacer>
        <Spacer pt={5} />
        <Styled.TagContainer id="sync-tag" isFjs={isFjs} />
      </Spacer>
    </Styled.Container>
  );
};
