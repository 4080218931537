import styled from "styled-components";
import media from "styled-media-query";

export const Container = styled.div`
  background: ${({ theme }) => theme.palette.white};
`;

export const LogoContainer = styled.div`
  img {
    width: 200px;
  }
  ${media.lessThan("large")`
    img {
      width: 100%;
    }
  `}
`;

export const UserMenuContainer = styled.div`
  cursor: pointer;
  ${media.lessThan("large")`
    width: 100%;
  `}
`;

export const AdContainer = styled.div`
  img {
    width: 540px;
  }
  ${media.lessThan("large")`
    display:none;
  `}
`;
