import styled from "styled-components";
import media from "styled-media-query";

export const Container = styled.div``;

export const DetailContainer = styled.div`
  background: ${({ theme }) => theme.palette.white};
  border-radius: 8px;
`;

export const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => theme.spacing * 2}px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.gray.light};
  ${media.lessThan("medium")`
    flex-wrap: wrap;
  `}
`;

export const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
  ${media.lessThan("large")`
    flex-wrap: wrap;
  `}
`;

export const NameContainer = styled.div``;

export const ButtonContainer = styled.div`
  display: flex;
  button {
    white-space: nowrap;
  }
  ${media.lessThan("medium")`
    flex-direction: column;
    align-content: space-between;
    button {
      margin-bottom: 10px;
    }
  `}
`;

export const EditorMenuContainer = styled.div`
  display: flex;
  align-items: center;
  ${media.lessThan("medium")`
    flex-wrap: wrap;
    button {
      margin-bottom: 10px;
    }
  `}
`;

export const EditorContainer = styled.div`
  min-width: 500px;
  width: 50%;
  ${media.lessThan("large")`
    min-width: 100px;
    width: 100%;
  `}
`;

export const EditorBodyContainer = styled.div`
  border: 2px solid ${({ theme }) => theme.palette.divider};
`;

export const ExplanationContainer = styled.ul`
  list-style-type: none;
`;
