import React, { useContext } from "react";
import * as Styled from "./styled";
import {
  Spacer,
  Flex,
  MenuList,
  Typography,
  Icon,
  Popover,
  Button,
} from "ingred-ui";
import { AuthContext } from "../../auth";
import { useNavigate, useLocation } from "react-router-dom";
import firebase from "firebase/app";
import Logo from "../../assets/tagviewer_logo.svg";
import DstAd from "../../assets/dst_banner.png";

type Props = {
  loginAvailable?: boolean;
  bannerAvailable?: boolean;
};

export const Header: React.FunctionComponent<Props> = ({
  loginAvailable = true,
  bannerAvailable = false,
}) => {
  const auth = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();

  const contents = [
    {
      text: `${auth.firebaseUser?.email}`,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onClick: () => {},
    },
    {
      text: `ログアウト`,
      onClick: () => {
        firebase
          .auth()
          .signOut()
          .then(() => {
            auth.loggedIn = false;
            auth.firebaseUser = null;
            navigate("/signin");
          });
      },
      divideTop: true,
    },
  ];

  const [wrapperElement, setWrapperElement] =
    React.useState<HTMLDivElement | null>(null);
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const onHandleIsOpen = (isOpen: boolean) => () => {
    setIsOpen(isOpen);
  };

  const handleMenuClose = () => {
    setIsOpen(false);
  };

  const handleClickLoginButton = () => {
    navigate("/signin", { state: { from: location } });
  };

  const handleClickLogo = () => {
    if (loginAvailable) navigate("/");
  };

  return (
    <Styled.Container>
      <Flex display="flex" justifyContent="space-between" alignItems="center">
        <Spacer p={2}>
          <Styled.LogoContainer onClick={handleClickLogo}>
            <img src={Logo} alt="TagViewer" />
          </Styled.LogoContainer>
        </Spacer>

        {bannerAvailable && (
          <Spacer pt={1}>
            <Styled.AdContainer>
              <a
                href="https://data-strap.com/lp/overview/?bn=tagviewer_header"
                rel="noopener noreferrer"
                target="_blank"
              >
                <img
                  src={DstAd}
                  alt="広告運用の面倒な作業をゼロへ DATA STRAP"
                />
              </a>
            </Styled.AdContainer>
          </Spacer>
        )}

        {loginAvailable && (
          <Spacer p={2}>
            {auth.firebaseUser ? (
              <Styled.UserMenuContainer>
                <Flex
                  ref={setWrapperElement}
                  display="flex"
                  onClick={onHandleIsOpen(!isOpen)}
                >
                  <Typography>{auth.firebaseUser?.displayName}</Typography>
                  <Icon name="arrow_bottom" />
                </Flex>
                <Popover
                  baseElement={wrapperElement}
                  positionPriority={["bottom-end"]}
                  offset={[0, 16]}
                  isOpen={isOpen}
                  onClose={handleMenuClose}
                >
                  <MenuList contents={contents} />
                </Popover>
              </Styled.UserMenuContainer>
            ) : (
              <Button
                color="secondary"
                size="small"
                onClick={handleClickLoginButton}
              >
                ログイン
              </Button>
            )}
          </Spacer>
        )}
      </Flex>
    </Styled.Container>
  );
};
