import React from "react";
import { Flex } from "ingred-ui";
import { ArrowButton } from "./internal/ArrowButton";

type Props = {
  isFirst?: boolean;
  isLast?: boolean;
  onClick: (direction: "next" | "prev") => void;
};

export const SimplePager: React.FunctionComponent<Props> = ({
  isFirst = false,
  isLast = false,
  onClick,
}) => {
  const handleClickPrev = () => {
    onClick("prev");
  };

  const handleClickNext = () => {
    onClick("next");
  };

  return (
    <Flex display="flex" alignItems="center">
      <ArrowButton
        isRight={false}
        disabled={isFirst}
        onClick={handleClickPrev}
      />

      <ArrowButton isRight disabled={isLast} onClick={handleClickNext} />
    </Flex>
  );
};
