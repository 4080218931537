import * as React from "react";
import * as Styled from "./styled";
import { CountChanger } from "./internal/CountChanger";
import { SortableHeaderCell } from "./internal/SortableHeaderCell";
import { CellCheckbox } from "./internal/CellCheckbox";
import { CellRadio } from "./internal/CellRadio";
import { Table } from "./internal/Table";
import { Typography, Spacer, Flex, ItemEmpty } from "ingred-ui";
import { TypographyProps } from "ingred-ui/dist/components/Typography/Typography";
import { ItemEmptyProps } from "ingred-ui/dist/components/ItemEmpty/ItemEmpty";
import { SimplePager } from "./internal/SimplePager";
import { TableTabs } from "./internal/TableTabs";
import { VerticalSpacing } from "./internal/Table/Row";
import { TabItem } from "./internal/TableTabs/TableTabs";

function isCheckableTab<T>(currentTabIndex: number, tabs?: Tab<T>[]) {
  return !!tabs && !tabs[currentTabIndex]?.disabledCheck;
}

function calculateRowSpan<T extends DataTableBaseData>(
  column: Column<T>,
  displayData: T[],
  startIndex: number,
): number {
  if (!column.enableMergeCell) return 1;
  const baseRow = displayData[startIndex];
  const baseCell = column.selector(baseRow);
  let rowSpan = 1;
  for (let idx = startIndex + 1; idx < displayData.length; idx++) {
    const comparisonRow = displayData[idx];
    const comparisonCell = column.selector(comparisonRow);
    if (comparisonRow.id !== baseRow.id || comparisonCell !== baseCell) {
      break;
    }
    rowSpan++;
  }
  return rowSpan;
}

export type DataTableBaseData = {
  id: number;
  selectDisabled?: boolean;
};

export type Column<T> = {
  name: string;
  value: string;
  selector: (data: T) => string | number;
  sortable?: boolean;
  width?: string;
  renderCell?: (data: T) => React.ReactNode;
  headerCell?: React.ReactNode;
  align?: TypographyProps["align"];
  enableMergeCell?: boolean;
};

type Tab<T> = {
  label: string;
  externalValue: string;
  disabledCheck?: boolean;
};

export type ExternalizeStateDataTableProps<T> = {
  data: T[];
  columns: Column<T>[];
  activeColumn: string;
  order?: "desc" | "asc";
  enablePagination?: boolean;
  onSelectRowsChange?: (rows: number[]) => void;
  onRadioChange?: (radio: number) => void;
  onHandlePerChange: (per: number) => void;
  onHandlePagerChange: (direction: "prev" | "next") => void;
  onHandleTabChange: (itemValue: string) => void;
  onHandleColumnChange: (value: string) => void;
  clearSelectedRows?: boolean;
  tabs?: Tab<T>[];
  tabIndex: number;
  itemEmptyProps?: ItemEmptyProps;
  per: number;
  isFirstPage: boolean;
  isLastPage: boolean;
  enableRuledLine?: boolean;
  verticalSpacing?: VerticalSpacing;
  fullWidth?: boolean;
  tableMaxHeight?: string;
  horizontalScrollable?: boolean;
};

// idを必須にしたい
export const ExternalizeStateDataTable = <T extends DataTableBaseData>({
  data: sourceData,
  columns,
  order,
  activeColumn,
  enablePagination = false,
  onSelectRowsChange,
  onRadioChange,
  onHandlePerChange,
  onHandlePagerChange,
  onHandleTabChange,
  onHandleColumnChange,
  clearSelectedRows,
  tabs,
  tabIndex,
  itemEmptyProps,
  per,
  isFirstPage,
  isLastPage,
  enableRuledLine = false,
  verticalSpacing = "medium",
  fullWidth = false,
  tableMaxHeight = "none",
  horizontalScrollable = false,
}: ExternalizeStateDataTableProps<T>) => {
  const showCheckbox = !!onSelectRowsChange;
  const [allSelected, setAllSelected] = React.useState(false);
  const [selectedRows, setSelectedRows] = React.useState<number[]>([]);
  const indeterminate = selectedRows.length > 0 && !allSelected;

  const showRadioButton = !!onRadioChange;
  const [selectedRow, setSelectedRow] = React.useState<number | null>(null);

  const showTabs = !!tabs;
  const [currentTabIndex, setCurrentTabIndex] = React.useState(tabIndex);

  const enableMergeCell = columns.some((column) => column.enableMergeCell);

  // 選択項目のクリア
  React.useEffect(() => {
    if (clearSelectedRows) {
      setSelectedRows([]);
      if (onSelectRowsChange) {
        onSelectRowsChange([]);
      }
    }
  }, [clearSelectedRows, onSelectRowsChange]);

  React.useEffect(() => {
    setSelectedRows([]);
    if (onSelectRowsChange) {
      onSelectRowsChange([]);
    }
  }, [onSelectRowsChange, sourceData]);

  // selectの変更をonSelectRowsChangeに伝える
  React.useEffect(() => {
    if (onSelectRowsChange) {
      onSelectRowsChange(selectedRows);
    }
  }, [selectedRows, onSelectRowsChange]);

  // radioの変更をonRadioChangeに伝える
  React.useEffect(() => {
    if (onRadioChange) {
      onRadioChange(selectedRow as number);
    }
  }, [selectedRow, onRadioChange]);

  const onTabChange = (item: TabItem) => {
    setCurrentTabIndex(item.value);
    onHandleTabChange(item.externalValue);
  };

  const onHandleCountChange = (per: number) => {
    onHandlePerChange(per);
  };

  const onHandleSelectCheckbox = (id: number) => () => {
    if (selectedRows.includes(id)) {
      setSelectedRows(selectedRows.filter((selectedId) => selectedId !== id));
    } else {
      setSelectedRows([...selectedRows, id]);
    }
  };

  const onHandleSelectRadioButton = (id: number) => () => {
    setSelectedRow(id);
  };

  const onHandleToggleCheckAll = () => {
    if (selectedRows.length > 0) {
      setSelectedRows([]);
      setAllSelected(false);
    } else {
      setSelectedRows(
        sourceData
          .filter((data) => !data.selectDisabled)
          .map((data) => data.id),
      );
      setAllSelected(true);
    }
  };

  return (
    <Styled.Container>
      <Styled.BorderContainer fullWidth={fullWidth}>
        {!!tabs && (
          <TableTabs
            value={currentTabIndex}
            items={tabs.map((tab, index) => ({
              label: tab.label,
              externalValue: tab.externalValue,
              value: index,
            }))}
            onChange={onTabChange}
          />
        )}
        <Styled.TableContainer
          maxHeight={tableMaxHeight}
          horizontalScrollable={horizontalScrollable}
        >
          <Table horizontalScrollable={horizontalScrollable}>
            <Table.Header>
              <Table.Row isStickyHeader={tableMaxHeight !== "none"}>
                {(!showTabs || isCheckableTab(currentTabIndex, tabs)) && (
                  <>
                    {showCheckbox && (
                      <CellCheckbox
                        header={true}
                        selected={selectedRows.length > 0}
                        indeterminate={indeterminate}
                        onClick={onHandleToggleCheckAll}
                      />
                    )}
                    {showRadioButton && <CellRadio header={true} />}
                  </>
                )}
                {columns.map((column) => (
                  <SortableHeaderCell
                    key={column.name}
                    sortable={column.sortable && !enableMergeCell}
                    order={activeColumn === column.value ? order : null}
                    width={column.width}
                    enableRuledLine={enableRuledLine}
                    onClick={() => onHandleColumnChange(column.value)}
                  >
                    {column.headerCell || column.name}
                  </SortableHeaderCell>
                ))}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {sourceData.length > 0 ? (
                sourceData.map((item, index) => (
                  <Table.Row
                    key={index} // eslint-disable-line react/no-array-index-key
                    verticalSpacing={verticalSpacing}
                    highlighted={
                      !item.selectDisabled &&
                      (selectedRows.includes(item.id) ||
                        selectedRow === item.id)
                    }
                    disableHoverHighlight={enableMergeCell}
                  >
                    {(!showTabs || isCheckableTab(currentTabIndex, tabs)) && (
                      <>
                        {showCheckbox &&
                          (item.selectDisabled ? (
                            <Table.Cell enableRuledLine={enableRuledLine} />
                          ) : (
                            <CellCheckbox
                              selected={selectedRows.includes(item.id)}
                              onClick={onHandleSelectCheckbox(item.id)}
                            />
                          ))}
                        {showRadioButton && (
                          <CellRadio
                            selected={item.id === selectedRow}
                            onClick={onHandleSelectRadioButton(item.id)}
                          />
                        )}
                      </>
                    )}
                    {columns.map((column) => (
                      <Table.Cell
                        key={column.name}
                        enableRuledLine={enableRuledLine}
                        rowSpan={calculateRowSpan(column, sourceData, index)}
                      >
                        {column.renderCell ? (
                          column.renderCell(item)
                        ) : (
                          <Typography align={column.align}>
                            {column.selector(item)}
                          </Typography>
                        )}
                      </Table.Cell>
                    ))}
                  </Table.Row>
                ))
              ) : (
                <tr>
                  <td
                    colSpan={
                      columns.length +
                      ((showCheckbox || showRadioButton) &&
                      (!showTabs || isCheckableTab(currentTabIndex, tabs))
                        ? 1
                        : 0)
                    }
                  >
                    <ItemEmpty
                      {...itemEmptyProps}
                      title={itemEmptyProps?.title || "見つかりませんでした"}
                    />
                  </td>
                </tr>
              )}
            </Table.Body>
          </Table>
        </Styled.TableContainer>
      </Styled.BorderContainer>
      {enablePagination && (
        <Spacer p={3}>
          <Flex
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <SimplePager
              isFirst={isFirstPage}
              isLast={isLastPage}
              onClick={onHandlePagerChange}
            />
            <CountChanger per={per} onChange={onHandleCountChange} />
          </Flex>
        </Spacer>
      )}
    </Styled.Container>
  );
};
