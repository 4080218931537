import React from "react";
import * as Styled from "./styled";
import { Typography, Spacer, Flex } from "ingred-ui";
import { SideBar } from "../Sidebar";

type Props = {
  sideBarChildren?: React.ReactNode;
};

export const DemoTemplate: React.FunctionComponent<Props> = (props) => {
  return (
    <Flex display="flex" alignItems="flex-start" justifyContent="center">
      <Styled.Container>
        <Typography weight="bold" size="xl">
          ポラーノの広場
        </Typography>
        <Spacer pt={3} />
        <Typography size="sm" color="secondary">
          前十七等官 レオーノ・キュースト誌
          <br />
          宮沢賢治 訳述
        </Typography>
        <Spacer pt={4} />
        <Styled.Text>
          そのころわたくしは、モリーオ市の博物局に勤めて居りました。
        </Styled.Text>
        <Spacer pt={3} />
        <Styled.ParagraphText>
          十八等官でしたから役所のなかでも、ずうっと下の方でしたし俸給ほうきゅうもほんのわずかでしたが、受持ちが標本の採集や整理で生れ付き好きなことでしたから、わたくしは毎日ずいぶん愉快にはたらきました。殊にそのころ、モリーオ市では競馬場を植物園に拵こしらえ直すというので、その景色のいいまわりにアカシヤを植え込んだ広い地面が、切符売場や信号所の建物のついたまま、わたくしどもの役所の方へまわって来たものですから、わたくしはすぐ宿直という名前で月賦で買った小さな蓄音器と二十枚ばかりのレコードをもって、その番小屋にひとり住むことになりました。わたくしはそこの馬を置く場所に板で小さなしきいをつけて一疋の山羊を飼いました。毎朝その乳をしぼってつめたいパンをひたしてたべ、それから黒い革のかばんへすこしの書類や雑誌を入れ、靴もきれいにみがき、並木のポプラの影法師を大股にわたって市の役所へ出て行くのでした。
        </Styled.ParagraphText>
        <Spacer pt={3} />
        <Styled.ParagraphText>
          あのイーハトーヴォのすきとおった風、夏でも底に冷たさをもつ青いそら、うつくしい森で飾られたモリーオ市、郊外のぎらぎらひかる草の波。
        </Styled.ParagraphText>
        <Spacer pt={3} />
        <Styled.ParagraphText>
          またそのなかでいっしょになったたくさんのひとたち、ファゼーロとロザーロ、羊飼のミーロや、顔の赤いこどもたち、地主のテーモ、山猫博士のボーガント・デストゥパーゴなど、いまこの暗い巨きな石の建物のなかで考えていると、みんなむかし風のなつかしい青い幻燈のように思われます。では、わたくしはいつかの小さなみだしをつけながら、しずかにあの年のイーハトーヴォの五月から十月までを書きつけましょう。
        </Styled.ParagraphText>
        <Spacer pt={5} pb={5}>
          <Styled.CenterText>一、遁げた山羊</Styled.CenterText>
        </Spacer>
        <Styled.ParagraphText>
          五月のしまいの日曜でした。わたくしは賑にぎやかな市の教会の鐘の音で眼をさましました。もう日はよほど登って、まわりはみんなきらきらしていました。時計を見るとちょうど六時でした。わたくしはすぐチョッキだけ着て山羊を見に行きました。すると小屋のなかはしんとして藁わらが凹んでいるだけで、あのみじかい角も白い髯も見えませんでした。
        </Styled.ParagraphText>
        <Spacer pt={3} />
        <Styled.Text>
          「あんまりいい天気なもんだから大将ひとりででかけたな。」
        </Styled.Text>
        <Spacer pt={3} />
        <Styled.ParagraphText>
          わたくしは半分わらうように半分つぶやくようにしながら、向うの信号所からいつも放して遊ばせる輪道の内側の野原、ポプラの中から顔をだしている市はずれの白い教会の塔までぐるっと見まわしました。けれどもどこにもあの白い頭もせなかも見えていませんでした。うまやを一まわりしてみましたがやっぱりどこにも居ませんでした。
        </Styled.ParagraphText>
        <Spacer pt={3} />
        <Styled.ParagraphText>
          「いったい山羊は馬だの犬のように前居たところや来る道をおぼえていて、そこへ戻っているということがあるのかなあ。」
        </Styled.ParagraphText>
        <Spacer pt={3} />
        <Styled.ParagraphText>
          わたくしはひとりで考えました。さあ、そう思うと早くそれを知りたくてたまらなくなりました。けれども役所のなかとちがって競馬場には物知りの年とった書記も居なければ、そんなことを書いた辞書もそこらにありませんでしたから、わたくしは何ということなしに輪道を半分通って、それからこの前山羊が村の人に連れられて来た路をそのまま野原の方へあるきだしました。
        </Styled.ParagraphText>
        <Spacer pt={3} />
        <Styled.ParagraphText>
          そこらの畑では燕麦えんばくもライ麦ももう芽をだしていましたし、これから何か蒔まくとこらしくあたらしく掘り起こされているところもありました。
        </Styled.ParagraphText>
      </Styled.Container>
      <SideBar {...props} />
    </Flex>
  );
};
