import styled from "styled-components";

// 70pxはヘッダーの高さ, 16pxはページ共通paddingの上下
export const Container = styled.div``;

export const NotFoundContainer = styled.div`
  height: calc(100vh - (70px + ${({ theme }) => theme.spacing * 2}px));
  padding: 0;
  display: flex;
  align-items: center;
`;
