import styled from "styled-components";

export const FormContainer = styled.div``;

export const FormGroup = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: ${({ theme }) => theme.spacing * 3}px 0
    ${({ theme }) => theme.spacing * 8}px;
  & + & {
    border-top: 2px solid ${({ theme }) => theme.palette.gray.light};
  }
`;

export const FormGroupLeft = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-height: 42px;
`;

export const FormGroupRight = styled.div`
  padding-left: ${({ theme }) => theme.spacing * 1}px;
  width: 400px;
`;

export const TextFieldContainer = styled.div`
  position: relative;
`;

export const EditorContainer = styled.div`
  min-width: 500px;
  width: 50%;
`;

export const EditorBodyContainer = styled.div`
  border: 2px solid ${({ theme }) => theme.palette.divider};
`;

export const ExplanationContainer = styled.ul`
  list-style-type: none;
`;
