import React from "react";
import * as Styled from "./styled";
import { Typography, Icon } from "ingred-ui";

type BreadcrumbData = {
  title: string;
  link?: string;
};

type Props = {
  items: BreadcrumbData[];
};

export const Breadcrumbs: React.FunctionComponent<Props> = ({ items }) => {
  return (
    <Styled.Container>
      {items.map((item, index) => (
        <React.Fragment key={item.title}>
          {item.link ? (
            <Styled.LinkContainer to={`/${item.link}`}>
              <Typography color="secondary" size="xs">
                {item.title}
              </Typography>
            </Styled.LinkContainer>
          ) : (
            <Typography
              color="secondary"
              size="xs"
              weight={index !== items.length - 1 ? "normal" : "bold"}
            >
              {item.title}
            </Typography>
          )}
          {index !== items.length - 1 && <Icon name="arrow_right" size="lg" />}
        </React.Fragment>
      ))}
    </Styled.Container>
  );
};
