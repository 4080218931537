import React, {
  useEffect,
  useState,
  useRef,
  useContext,
  useCallback,
} from "react";
import {
  Typography,
  Spacer,
  Button,
  ButtonGroup,
  ActionButton,
  Toast,
  LoadingBar,
} from "ingred-ui";
import { useParams, useNavigate } from "react-router-dom";
import { Tag, Author } from "../../types";
import { AuthContext } from "../../auth";
import { getTag, updateTag, deleteTag, getAuthor } from "../../client";
import { default as AceEditor } from "react-ace";
import "ace-builds/src-noconflict/mode-html";
import "ace-builds/src-noconflict/theme-github";
import { Breadcrumbs } from "../../components/Breadcrumbs";
import { DeleteModal } from "./internal/DeleteModal";
import { EditModal } from "./internal/EditModal";
import { Header } from "../../components/Header";
import { Loading } from "../../components/Loading";
import * as Styled from "./styled";
import moment from "moment";

type Props = {};

export const Detail: React.FunctionComponent<Props> = () => {
  // routerからpath parameterを取得している
  // refs https://reacttraining.com/blog/react-router-v5-1/
  const { id } = useParams<{ id: string }>();
  const [tag, setTag] = useState<Tag>();
  const [loading, setLoading] = useState<boolean>(false);
  const [updateLoading, setUpdateLoading] = useState<boolean>(false);
  const [tagNotFound, setTagNotFound] = useState<boolean>(false);
  const [deleteModalId, setDeleteModalId] = useState<string | null>(null);
  const [editModalId, setEditModalId] = useState<string | null>(null);
  const editorRef = useRef<AceEditor>(null);
  const navigate = useNavigate();
  const auth = useContext(AuthContext);
  const { addToast } = Toast.useToasts();

  const fetchTag = useCallback(async () => {
    setLoading(true);
    if (id === undefined) {
      return false;
    }
    const doc = await getTag(id);
    if (doc.data()) {
      const tag: Tag = {
        ...doc.data(),
        created: doc.data()?.created.toDate(),
        updated: doc.data()?.updated.toDate(),
        tagId: doc.id,
      } as Tag;

      if (auth.firebaseUser) {
        const author = await getAuthor(tag.authorRef.id);
        tag.createdBy = author.data() as Author;
      }

      setTag(tag);
      setLoading(false);
    } else {
      setTagNotFound(true);
    }
  }, [auth.firebaseUser, id]);

  useEffect(() => {
    fetchTag();
  }, [id, fetchTag]);

  if (tagNotFound) {
    navigate("/notfound");
    return null;
  } else if (!tag) {
    return <div>{loading && <Loading />}</div>;
  }

  const handleCopyToClipboard = () => {
    editorRef.current?.editor.selectAll();
    editorRef.current?.editor.focus();
    document.execCommand("copy");
    addToast("広告タグをクリップボードにコピーしました。", {
      appearance: "success",
      autoDismiss: true,
    });
  };

  const onHandleDelete = async (tagId: string) => {
    await deleteTag(tagId).catch((e) => {
      addToast("タグの削除に失敗しました。", {
        appearance: "error",
        autoDismiss: true,
      });
    });
    navigate("/");
  };

  const onHandleOpenPreview = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.preventDefault();
    window.open(`/preview/${tag.tagId}`);
  };

  const onHandleOpenAMPPreview = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.preventDefault();
    window.open(`/ampPreview/${tag.tagId}`);
  };

  const onHandleEdit = async ({
    title,
    body,
    memo,
    tagId,
  }: Pick<Tag, "title" | "body" | "memo" | "tagId">) => {
    setUpdateLoading(true);
    await updateTag(title, body, memo, tagId).catch((e) => {
      addToast("タグの更新に失敗しました。", {
        appearance: "error",
        autoDismiss: true,
      });
    });
    setUpdateLoading(false);
    await fetchTag();
    setEditModalId(null);
  };

  const onHandleChangeDeleteModalId = (id: string | null) => () =>
    setDeleteModalId(id);

  const onHandleChangeEditModalId = (id: string | null) => () =>
    setEditModalId(id);

  return (
    <Styled.Container>
      <Header bannerAvailable={true} />
      <Spacer pt={1} />
      <Spacer p={1}>
        {auth.firebaseUser && (
          <Spacer pl={2} pb={2} pt={1}>
            <Breadcrumbs
              items={[
                {
                  title: "タグ一覧",
                  link: "tags",
                },
                {
                  title: tag.title,
                },
              ]}
            />
          </Spacer>
        )}
        <Styled.DetailContainer>
          {loading ? (
            <LoadingBar />
          ) : (
            <div>
              <Styled.TitleContainer>
                <Styled.NameContainer>
                  <Typography weight="bold" size="xl">
                    {tag.title}
                  </Typography>
                  <Spacer p={0.5} />
                  <Typography size="xs">
                    作成日時: {moment(tag.created).format("YYYY/MM/DD HH:mm")}
                  </Typography>
                  <Typography size="xs">
                    更新日時: {moment(tag.updated).format("YYYY/MM/DD HH:mm")}
                  </Typography>
                </Styled.NameContainer>
                <Spacer p={1}>
                  <Styled.ButtonContainer>
                    {auth.firebaseUser && (
                      <Spacer pr={1}>
                        <ButtonGroup size="medium">
                          <Button
                            inline
                            onClick={onHandleChangeEditModalId(tag.tagId)}
                          >
                            編集する
                          </Button>
                          <Button
                            inline
                            onClick={onHandleChangeDeleteModalId(tag.tagId)}
                          >
                            削除する
                          </Button>
                        </ButtonGroup>
                      </Spacer>
                    )}
                    {editModalId === tag.tagId && (
                      <EditModal
                        tag={tag}
                        loading={updateLoading}
                        onClose={onHandleChangeEditModalId(null)}
                        onSubmit={onHandleEdit}
                      />
                    )}
                    {deleteModalId === tag.tagId && (
                      <DeleteModal
                        tag={tag}
                        onClose={onHandleChangeDeleteModalId(null)}
                        onSubmit={onHandleDelete}
                      />
                    )}
                    <Button
                      inline
                      size="medium"
                      onClick={handleCopyToClipboard}
                    >
                      広告タグをクリップボードにコピーする
                    </Button>
                  </Styled.ButtonContainer>
                </Spacer>
              </Styled.TitleContainer>

              <Spacer p={2}>
                <Styled.ContentContainer>
                  <Styled.EditorContainer>
                    <Spacer pb={2}>
                      <Styled.EditorMenuContainer>
                        <Typography weight="bold" size="xl">
                          広告タグ
                        </Typography>
                        <Spacer pr={1} />
                        <ActionButton
                          icon="external_link"
                          onClick={onHandleOpenPreview}
                        >
                          プレビューを表示
                        </ActionButton>
                        <Spacer pr={1} />
                        <ActionButton
                          icon="external_link"
                          onClick={onHandleOpenAMPPreview}
                        >
                          AMPタグ用プレビューを表示
                        </ActionButton>
                      </Styled.EditorMenuContainer>
                    </Spacer>
                    <Styled.EditorBodyContainer>
                      <AceEditor
                        ref={editorRef}
                        value={tag.body}
                        readOnly={true}
                        wrapEnabled={true}
                        showPrintMargin={false}
                        mode="html"
                        theme="github"
                        name="tag-source"
                        width="100%"
                        editorProps={{ $blockScrolling: true }}
                      />
                    </Styled.EditorBodyContainer>
                  </Styled.EditorContainer>
                  <Spacer pr={2} />
                  <Styled.EditorContainer>
                    <Spacer pb={2}>
                      <Spacer p={0.6}>
                        <Typography weight="bold" size="xl">
                          設置時の注意事項
                        </Typography>
                      </Spacer>
                    </Spacer>
                    <Styled.EditorBodyContainer>
                      <AceEditor
                        value={tag.memo}
                        readOnly={true}
                        wrapEnabled={true}
                        showPrintMargin={false}
                        mode="markdown"
                        theme="github"
                        name="memo"
                        width="100%"
                        editorProps={{ $blockScrolling: true }}
                      />
                    </Styled.EditorBodyContainer>
                  </Styled.EditorContainer>
                </Styled.ContentContainer>
              </Spacer>
            </div>
          )}
        </Styled.DetailContainer>
      </Spacer>
    </Styled.Container>
  );
};
