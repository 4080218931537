import React from "react";
import * as Styled from "./styled";
import { Typography, Spacer, Card } from "ingred-ui";
import { Link } from "react-router-dom";

export const DemoLinksSideBar: React.FunctionComponent = () => {
  const demoLinksArray = [
    {
      name: "surface PC",
      url: "/demo/surface_pc",
    },
    {
      name: "surface SP",
      url: "/demo/surface_sp",
    },
    {
      name: "impress",
      url: "/demo/impress",
    },
    {
      name: "ゲート広告",
      url: "/demo/gate",
    },
    {
      name: "レクタングル",
      url: "/demo/rectangle",
    },
    {
      name: "ビルボード広告",
      url: "/demo/billboard",
    },
  ];
  return (
    <Card ml={3} p={4} minWidth="280px">
      <Typography weight="bold" size="xl">
        商材のデモ画面
      </Typography>
      <Spacer pt={2} />
      <Styled.DemoLinkList>
        {demoLinksArray.map((item, index) => (
          <React.Fragment key={index}>
            <Styled.DemoLinkListItem>
              <Link to={item.url} target="_blank">
                {item.name}
              </Link>
            </Styled.DemoLinkListItem>
            <Spacer pt={1} />
          </React.Fragment>
        ))}
      </Styled.DemoLinkList>
    </Card>
  );
};
