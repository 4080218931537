import React from "react";
import * as Styled from "./styled";
import { Typography, Spacer } from "ingred-ui";

type Props = {
  sideBarChildren?: React.ReactNode;
};

export const SideBar: React.FunctionComponent<Props> = (props) => {
  const { sideBarChildren } = props;
  return (
    <Styled.Container>
      <Styled.Inner>
        <Typography weight="bold" size="md">
          人気ランキング
        </Typography>
        <Spacer pt={2} />
        <Styled.DummyArea />
        <Spacer pt={1} />
        <Styled.DummyArea />
        <Spacer pt={1} />
        <Styled.DummyArea />
        <Spacer pt={1} />
        <Styled.DummyArea />
        <Spacer pt={1} />
        <Styled.DummyArea />
      </Styled.Inner>
      {sideBarChildren && (
        <>
          <Spacer pt={1} />
          {sideBarChildren}
        </>
      )}
    </Styled.Container>
  );
};
