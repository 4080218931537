import styled from "styled-components";
import media from "styled-media-query";

export const FormContainer = styled.div``;

export const FormGroup = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: ${({ theme }) => theme.spacing * 3}px 0
    ${({ theme }) => theme.spacing * 8}px;
  & + & {
    border-top: 2px solid ${({ theme }) => theme.palette.gray.light};
  }
`;

export const FormGroupLeft = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-height: 42px;
`;

export const FormGroupRight = styled.div`
  padding-left: ${({ theme }) => theme.spacing * 1}px;
  width: 400px;
  ${media.lessThan("large")`
    width: 60%;
  `}
`;

export const TextFieldContainer = styled.div`
  position: relative;
  ${media.lessThan("large")`
    width: 100%;
  `}
`;

export const ContentContainer = styled.div`
  display: flex;
  justify-content: left;
  ${media.lessThan("large")`
    flex-wrap: wrap;
  `}
`;

export const EditorContainer = styled.div`
  min-width: 500px;
  width: 50%;
  ${media.lessThan("large")`
    min-width: 100%;
  `}
`;

export const EditorBodyContainer = styled.div`
  border: 2px solid ${({ theme }) => theme.palette.divider};
`;

export const PreviewButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  ${media.lessThan("large")`
    flex-wrap: wrap;
    justify-content: left;
  `}
  ${media.lessThan("medium")`
    button {
      margin-top: 5px;
      margin-bottom: 5px;
    }
  `}
`;

export const ExplanationContainer = styled.ul`
  list-style-type: none;
`;
