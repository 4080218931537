import React, { useContext } from "react";
import { ErrorText, Spacer, Button } from "ingred-ui";
import * as Styled from "./styled";
import { addAuthor } from "../../client";
import { AuthContext } from "../../auth";
import firebase from "firebase/app";
import { Navigate, useNavigate, useLocation } from "react-router-dom";
import { Header } from "../../components/Header";

type Props = {};

export const SignIn: React.FunctionComponent<Props> = () => {
  const [error, setError] = React.useState<string>("");
  const auth = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const fromPath = location.state ? location.state.from : "tags";

  if (auth.loggedIn) {
    return <Navigate to={fromPath} />;
  }

  const handleGoogleLogin = async () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    // NOTE(suzuken): ログイン先ドメインを絞れる
    // https://firebase.google.com/docs/reference/js/firebase.auth.GoogleAuthProvider
    provider.setCustomParameters({
      hd: ["cartahd.com"],
    });

    try {
      await firebase
        .auth()
        .setPersistence(firebase.auth.Auth.Persistence.LOCAL);
      const result = await firebase.auth().signInWithPopup(provider);
      if (result.user === null) {
        throw new Error("firebaseからユーザ情報が取得できません。");
      }
      auth.loggedIn = true;
      auth.firebaseUser = result.user;
      // firestoreにユーザ情報を追加しておく
      await addAuthor(
        auth.firebaseUser.uid,
        auth.firebaseUser.email,
        auth.firebaseUser.displayName,
      );
      navigate(`/${fromPath}`);
    } catch (e) {
      if (e instanceof Error) {
        setError(e.message);
      }
    }
  };

  return (
    <Styled.Container>
      <Header loginAvailable={false} />
      <Spacer p={1}>
        <Styled.ButtonContainer>
          <Button inline size="medium" onClick={() => handleGoogleLogin()}>
            ログイン
          </Button>
          <Spacer pt={2} />
          {error && <ErrorText>{error}</ErrorText>}
        </Styled.ButtonContainer>
      </Spacer>
    </Styled.Container>
  );
};
