import React, { useCallback, useContext, useEffect, useState } from "react";
import { Toast } from "ingred-ui";
import firebase from "firebase/app";
import { isFluctOrigin } from "./utils";
import { AuthContext } from "../../auth";
import { Loading } from "../../components/Loading";
import { createTag } from "../../client";
import { useNavigate } from "react-router";

type MessageFromMs = {
  title: string;
  body: string;
  memo: string;
};

/**
 * fluct_msからwindow.open()されてこのページは開かれる
 * window.postMessage()で送られてきたタグ群を保存し、タグ一覧ページヘの遷移する
 * ローカルでの検証方法: https://github.com/voyagegroup/fluct-tagviewer/pull/219#issuecomment-862110184
 */
export const SaveTagsFromMs: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const auth = useContext(AuthContext);
  const { addToast } = Toast.useToasts();

  const saveTags = useCallback(
    async (tag: MessageFromMs) => {
      const user = firebase.auth().currentUser;
      await createTag(tag.body, tag.title, tag.memo, user?.uid);

      // MEMO: 何故か直後に遷移するとinsertしたデータのcreated, updatedがnullになる
      window.setTimeout(() => navigate("/tags"), 1000);
    },
    [navigate],
  );

  useEffect(() => {
    if (!window.opener) return;
    if (!auth.loggedIn) {
      addToast("ログインしてから再度やり直してください。", {
        appearance: "error",
      });
      navigate("/signin");
    }
    const handleGetMessage = (event: MessageEvent<MessageFromMs>) => {
      if (!isFluctOrigin(event.origin)) return;
      saveTags(event.data);
    };
    window.addEventListener("message", handleGetMessage);
    window.opener.postMessage({ logged_in: auth.loggedIn }, "*");
    return () => {
      window.removeEventListener("message", handleGetMessage);
    };
  }, [auth, addToast, navigate, saveTags]);

  return <Loading />;
};
