export const isFluctOrigin = (origin: string): boolean => {
  const FLUCT_MS_BRANCH_ENV_REGEX =
    /^https:\/\/[-a-zA-Z0-9]*\.fluct-jp\.hub\.fluct\.me$/;
  switch (true) {
    case origin === "https://fluct.jp": // production
    case origin === "https://fluct-jp.hub.fluct.me": // pre
    case origin.match(FLUCT_MS_BRANCH_ENV_REGEX) !== null: // branch
    case origin === "https://fluct.jp.local": // local
      return true;
    default:
      return false;
  }
};
