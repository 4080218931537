/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable @typescript-eslint/no-require-imports */
import React, { useEffect } from "react";
import { Spacer } from "ingred-ui";
import { Header } from "../../components/Header";
import * as Styled from "./styled";
import { DemoTemplate } from "../../components/Template";
import { StorageKey } from "../../constants/storageKeys";

export const Billboard: React.FunctionComponent = () => {
  let gid = "";
  let uid = "";
  if (document.body.clientWidth > 768) {
    // fluct グループ名「fluctmagazine_970x250_Web_すべて_インライン_ビルボード」
    gid = "1000148424";
    uid = "1000244372";
  } else {
    // fluct グループ名「fluctmagazine（スマホ）_320x180_Web_すべて_インライン_ビルボード」
    gid = "1000148425";
    uid = "1000244373";
  }
  const tagBody = `
  <script type="text/javascript" src="https://pdn.adingo.jp/p.js" async=""></script>

  <script>
  var className = "fluct-unit-${uid}";
  var contentNode = document.getElementsByClassName("l-contents__inner")[0];
  var billboardNode = document.createElement("div");
  billboardNode.className = "fluct-unit-${uid}";
  billboardNode.style.margin = "auto";
  var fluctAdScript = fluctAdScript || {};
  fluctAdScript.cmd = fluctAdScript.cmd || [];
  fluctAdScript.cmd.push(function (cmd) {
    cmd.setGamTargetingMap('%%PATTERN:TARGETINGMAP%%');
    cmd.loadByGroup(${gid});
    cmd.display(".fluct-unit-${uid}", "${uid}");
  });
</script>
  `;
  useEffect(() => {
    // インターステイシャル広告の表示期間制限を初期化
    if (localStorage.getItem(StorageKey.INST_BUFFER)) {
      localStorage.removeItem(StorageKey.INST_BUFFER);
    }
    const postscribe = require("postscribe");
    postscribe("#billboard", tagBody);
  }, []);

  return (
    <Styled.Container>
      <Header />
      <Spacer pt={2} />
      <Styled.FlexRowCenter>
        <div id="billboard" className={`fluct-unit-${uid}`} />
      </Styled.FlexRowCenter>
      <Spacer pt={2} />
      <Spacer p={1} pb={3}>
        <DemoTemplate />
      </Spacer>
      <link rel="preload" as="script" href={"https://pdn.adingo.jp/p.js"} />
      <link
        rel="preconnect"
        href="https://sh.adingo.jp"
        crossOrigin="use-credentials"
      />
    </Styled.Container>
  );
};
