import React from "react";
import { ItemEmpty, Spacer } from "ingred-ui";
import { Header } from "../../components/Header";
import * as Styled from "./styled";

type Props = {};

export const NotFound: React.FunctionComponent<Props> = () => {
  return (
    <Styled.Container>
      <Header />
      <Spacer p={1} />
      <Styled.NotFoundContainer>
        <ItemEmpty title="このタグは削除されたか、存在しません。" />
      </Styled.NotFoundContainer>
    </Styled.Container>
  );
};
