import styled from "styled-components";
import { Link } from "react-router-dom";

export const Container = styled.div`
  display: flex;
  align-items: center;
  height: 24px;
`;

export const LinkContainer = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.palette.gray.light};
  :hover {
    text-decoration: underline;
    text-decoration-color: ${({ theme }) => theme.palette.gray.light};
  }
`;
