import React, { useState } from "react";
import * as Styled from "./styled";
import {
  TextField,
  ConfirmModal,
  Flex,
  Typography,
  Spacer,
  Badge,
  ActionButton,
} from "ingred-ui";
import { default as AceEditor } from "react-ace";
import "ace-builds/src-noconflict/mode-html";
import "ace-builds/src-noconflict/mode-markdown";
import "ace-builds/src-noconflict/theme-github";
import { StorageKey } from "../../../../constants/storageKeys";
import { Tag } from "../../../../types";

type Props = {
  onClose: () => void;
  onSubmit: ({
    body,
    title,
    memo,
  }: Pick<Tag, "title" | "body" | "memo">) => void;
  loading: boolean;
};

const CreateModal: React.FunctionComponent<Props> = ({
  onClose,
  onSubmit,
  loading,
}) => {
  const [body, setBody] = useState<Tag["body"]>("");
  const [memo, setMemo] = useState<Tag["memo"]>("");
  const [title, setTitle] = useState<Tag["title"]>("");

  const onHandleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    onSubmit({ body, title, memo });
  };

  const onHandleOpenPreview = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.preventDefault();
    localStorage.setItem(StorageKey.TAG_BODY_BUFFER, body);
    window.open("/preview");
  };

  const onHandleOpenAMPPreview = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.preventDefault();
    localStorage.setItem(StorageKey.TAG_BODY_BUFFER, body);
    window.open("/ampPreview");
  };

  const handleTitleUpdate = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
  };

  return (
    <ConfirmModal
      fullSize={true}
      title="タグの新規作成"
      confirmText="保存する"
      buttonColor="primary"
      disabled={!title || !body}
      loading={loading}
      onClose={onClose}
      onSubmit={onHandleSubmit}
    >
      <Styled.FormContainer>
        <Styled.FormGroup>
          <Styled.FormGroupLeft>
            <Typography weight="bold">タイトル</Typography>
            <Spacer pr={1} />
            <Badge color="danger">必須</Badge>
          </Styled.FormGroupLeft>
          <Styled.FormGroupRight>
            <Styled.TextFieldContainer>
              <TextField
                name="title"
                value={title}
                onChange={handleTitleUpdate}
              />
            </Styled.TextFieldContainer>
          </Styled.FormGroupRight>
        </Styled.FormGroup>
        <Flex display="flex">
          <Styled.EditorContainer>
            <Spacer pb={2}>
              <Flex display="flex" alignItems="center">
                <Typography weight="bold">広告タグ</Typography>
                <Spacer pr={1} />
                <Badge color="danger">必須</Badge>
                <Spacer pr={1} />
                <ActionButton
                  icon="external_link"
                  onClick={onHandleOpenPreview}
                >
                  プレビューを表示
                </ActionButton>
                <Spacer pr={1} />
                <ActionButton
                  icon="external_link"
                  onClick={onHandleOpenAMPPreview}
                >
                  AMPタグ用プレビューを表示
                </ActionButton>
              </Flex>
            </Spacer>
            <Styled.EditorBodyContainer>
              <AceEditor
                value={body}
                wrapEnabled={true}
                showPrintMargin={false}
                mode="html"
                theme="github"
                name="tag-source"
                width="100%"
                editorProps={{ $blockScrolling: true }}
                onChange={(value) => setBody(value)}
              />
            </Styled.EditorBodyContainer>
          </Styled.EditorContainer>
          <Spacer pr={2} />
          <Styled.EditorContainer>
            <Spacer pb={2}>
              <Spacer p={0.8}>
                <Typography weight="bold">設置時の注意事項など</Typography>
              </Spacer>
            </Spacer>
            <Styled.EditorBodyContainer>
              <AceEditor
                value={memo}
                wrapEnabled={true}
                showPrintMargin={false}
                mode="markdown"
                theme="github"
                name="memo"
                width="100%"
                editorProps={{ $blockScrolling: true }}
                onChange={(value) => setMemo(value)}
              />
            </Styled.EditorBodyContainer>
          </Styled.EditorContainer>
        </Flex>
      </Styled.FormContainer>
      <Spacer p={2}>
        <Styled.ExplanationContainer>
          <Typography component="li" lineHeight="1.7" size="lg">
            広告タグ欄にはタグスクリプト本体のみを貼り付けてください。
          </Typography>
          <Typography component="li" lineHeight="1.7" size="lg">
            タグを共有するクライアントへの説明などを添えたい場合は「設置時の注意事項など」に記入してください。
          </Typography>
          <Typography component="li" lineHeight="1.7" size="lg">
            貼り付けたタグの表示を確認したい場合は「プレビューを表示」
            ボタンをクリックしてください。タグのプレビュー画面が別タブで表示されます。
          </Typography>
        </Styled.ExplanationContainer>
      </Spacer>
    </ConfirmModal>
  );
};

export { CreateModal };
