import { Config } from ".";

export const config: Config = {
  apiKey: process.env.REACT_APP_PRODUCTION_API_KEY,
  authDomain: "fluct-tagviewer-88dc9.firebaseapp.com",
  databaseURL: "https://fluct-tagviewer-88dc9.firebaseio.com",
  projectId: "fluct-tagviewer",
  storageBucket: "fluct-tagviewer.appspot.com",
  messagingSenderId: "286246244266",
  appId: "1:286246244266:web:aeaed04de52efae5cde5d3",
};
