import styled from "styled-components";
import media from "styled-media-query";

export const Container = styled.div`
  width: 22%;
  min-width: 296px;
  margin-left: 40px;
  max-width: 310px;
  ${media.lessThan("large")`
      display: none;
  `}
`;

export const Inner = styled.div`
  background: ${({ theme }) => theme.palette.white};
  border-radius: 8px;
  padding: 24px;
`;

export const DummyArea = styled.div`
  width: 100%;
  height: 24px;
  background-color: #f5f7f8;
`;

export const DemoLinkList = styled.ul`
  width: 100%;
  list-style: inside;
`;

export const DemoLinkListItem = styled.li`
  color: #0b82f4;
  text-align: left;
  font-size: 16px;
  ::marker {
    color: #041c33;
  }
`;
