import styled from "styled-components";
import media from "styled-media-query";

export const Container = styled.div`
  width: 48%;
  padding: 32px 48px;
  border-radius: 8px;
  background: ${({ theme }) => theme.palette.white};
  max-width: 676px;
  ${media.lessThan("large")`
    width: 100%;
    padding: 32px 24px;
  `}
`;

export const ParagraphText = styled.p`
  font-size: 14px;
  color: #041c33;
  line-height: 1.8;
  text-indent: 1em;
  text-align: left;
`;
export const Text = styled.p`
  font-size: 14px;
  color: #041c33;
  line-height: 1.8;
  text-align: left;
`;
export const CenterText = styled.p`
  font-size: 14px;
  color: #041c33;
  line-height: 1.8;
`;
