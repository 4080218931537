import React from "react";
import { Route, RouteProps } from "react-router-dom";

type MultipleRouteProps = RouteProps & {
  paths: string[];
};
export const RenderMultiRoutes = ({
  element: Element,
  paths,
  ...rest
}: MultipleRouteProps) =>
  paths.map((path) => (
    <Route key={path} path={path} {...rest} element={Element} />
  ));
