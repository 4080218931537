import React, { useState, useEffect, useRef, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Spacer, Typography } from "ingred-ui";
import { getTag } from "../../client";
import { StorageKey } from "../../constants/storageKeys";
import { Header } from "../../components/Header";
import { Loading } from "../../components/Loading";
import { Tag } from "../../types";
import * as Styled from "./styled";

export const AMPPreview: React.FunctionComponent = () => {
  const { id } = useParams<{ id: string }>();
  const [loading, setLoading] = useState<boolean>(false);
  const [tagNotFound, setTagNotFound] = useState<boolean>(false);
  const [tagBody, setTagBody] = useState<string>();
  const divRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  const fetchTag = useCallback(async () => {
    setLoading(true);
    if (id === undefined) {
      return false;
    }
    const doc = await getTag(id);
    if (doc.data()) {
      const tag: Tag = {
        ...doc.data(),
      } as Tag;

      setTagBody(tag.body);
      setLoading(false);
    } else {
      setTagNotFound(true);
    }
  }, [id]);

  useEffect(() => {
    if (id) {
      fetchTag();
    } else if (localStorage.getItem(StorageKey.TAG_BODY_BUFFER)) {
      setTagBody(localStorage.getItem(StorageKey.TAG_BODY_BUFFER) as string);
    }
  }, [id, fetchTag]);

  useEffect(() => {
    if (divRef.current && tagBody) {
      const tagHtml = document
        .createRange()
        .createContextualFragment(
          `<script async src="https://cdn.ampproject.org/v0.js"></script>` +
            `<script async custom-element="amp-ad" src="https://cdn.ampproject.org/v0/amp-ad-0.1.js"></script>` +
            `<script async custom-element="amp-sticky-ad" src="https://cdn.ampproject.org/v0/amp-sticky-ad-1.0.js"></script>` +
            tagBody,
        );
      divRef.current.appendChild(tagHtml);
    }
  }, [tagBody, divRef]);

  if (tagNotFound) {
    navigate("/notfound");
    return null;
  } else if (!tagBody) {
    return <div>{loading && <Loading />}</div>;
  }

  return (
    <Styled.Container>
      <Header loginAvailable={false} />
      <Spacer p={5}>
        <Typography lineHeight="1.7" size="xxl">
          このページはAMPタグ用プレビューページです。
        </Typography>
        <Typography lineHeight="1.7" weight="bold" size="xxl">
          スマートフォン用広告はPCビューからは見ることができない場合があります。
          その場合はPCブラウザのUA（User
          Agent）をスマートフォンにして確認するか、
          <br />
          実際のスマートフォンにてのご確認をお願いいたします。
        </Typography>
        <Typography lineHeight="1.7" size="xxl">
          タグの内容を更新した場合、この画面を閉じてからもう一度
          「プレビューを表示」ボタンをクリックして再度ご確認ください。
        </Typography>
        <Typography lineHeight="1.7" size="xxl">
          タグが表示されない場合は、何度かページリロードを行ってみてください。
        </Typography>
      </Spacer>
      <div ref={divRef} />
      <Spacer pb={30} />
    </Styled.Container>
  );
};
