/* eslint-disable @typescript-eslint/no-var-requires */
export type Config = {
  apiKey: string | undefined;
  authDomain: string;
  databaseURL: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
};

export const config: Config = {
  // eslint-disable-next-line @typescript-eslint/no-require-imports
  ...require(`./config.${process.env.REACT_APP_MODE}`).config,
};
