/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable @typescript-eslint/no-require-imports */
import React, { useEffect } from "react";
import { Spacer } from "ingred-ui";
import { Header } from "../../components/Header";
import * as Styled from "./styled";
import { DemoTemplate } from "../../components/Template";
import { StorageKey } from "../../constants/storageKeys";

export const SurfacePc: React.FunctionComponent = () => {
  const tagBody = `
  <script type="text/javascript" src="https://pdn.adingo.jp/p.js" async></script>
  <script type="text/javascript">
    var fluctAdScript = fluctAdScript || {};
    fluctAdScript.cmd = fluctAdScript.cmd || [];
    fluctAdScript.cmd.push(function (cmd) {
      cmd.loadByGroup("1000120674");
      cmd.display("#fluct-unit-1000208613", "1000208613");
    });
  </script>
  <script async src="https://dmp.im-apps.net/sdk/im-uid.ctp.js"></script>
  <script type="text/javascript">
    window.IMUIDRequest = window.IMUIDRequest || [];
    IMUIDRequest.push({
        customerId: 6837, /** fluctの検証用ID **/
        callback: (res) => {
            console.log('Universal Identifier is ' + res.uid)
            if (res.uid) {
          /** fire tag with uid parameter **/
            }
        },
        callbackTimeout: 1000
    });
  </script>
  `;
  useEffect(() => {
    // インターステイシャル広告の表示期間制限を初期化
    if (localStorage.getItem(StorageKey.INST_BUFFER)) {
      localStorage.removeItem(StorageKey.INST_BUFFER);
    }
    const postscribe = require("postscribe");
    postscribe("#fluct-unit-1000208613", tagBody);
  }, []);

  return (
    <Styled.Container>
      <Header />
      <Spacer pt={2} />
      <Spacer p={1} pb={3}>
        <DemoTemplate />
      </Spacer>
      <link rel="preload" as="script" href="https://pdn.adingo.jp/p.js" />
      <link
        rel="preconnect"
        href="https://sh.adingo.jp"
        crossOrigin="use-credentials"
      />
      <div id="fluct-unit-1000208613" />
    </Styled.Container>
  );
};
