/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable @typescript-eslint/no-require-imports */
import React, { useEffect } from "react";
import { Spacer } from "ingred-ui";
import * as Styled from "./styled";
import { DemoTemplate } from "../../components/Template";
import { StorageKey } from "../../constants/storageKeys";
import { Helmet, HelmetProvider } from "react-helmet-async";

export const Gate: React.FunctionComponent = () => {
  const tagBody = `<script type="text/javascript">
  var gcmMediaInfo_570c3fcd83df41d0a107d6fe71d237d8 = {
    "iel": "#gsm-gcm-ad"
  };
  </script>`;
  useEffect(() => {
    // インターステイシャル広告の表示期間制限を初期化
    if (localStorage.getItem(StorageKey.INST_BUFFER)) {
      localStorage.removeItem(StorageKey.INST_BUFFER);
    }
    const postscribe = require("postscribe");
    postscribe("#gsm-gcm-ad", tagBody);
  }, []);
  return (
    <HelmetProvider>
      <Helmet>
        <script
          src="https://gcmadv-cdn.goldspotmedia.com/delivery/ad/570c3fcd-83df-41d0-a107-d6fe71d237d8?gi=gcmInfo_570c3fcd83df41d0a107d6fe71d237d8"
          type="text/javascript"
        />
        <script
          src="https://gcmast-cdn.goldspotmedia.com/libs/gcm-player/1.9.1/gcm-player.min.js?tg=gcmInfo_570c3fcd83df41d0a107d6fe71d237d8&tm=gcmMediaInfo_570c3fcd83df41d0a107d6fe71d237d8"
          type="text/javascript"
        />
      </Helmet>
      <Styled.Container>
        <Spacer p={1} pb={3}>
          <DemoTemplate />
        </Spacer>
        <div
          id="gsm-gcm-ad"
          className="gsm-gcm-ad"
          style={{ margin: "0 auto", padding: 0, position: "relative" }}
        />
      </Styled.Container>
    </HelmetProvider>
  );
};
