/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Spacer, Typography } from "ingred-ui";
import { Header } from "../../components/Header";
import * as Styled from "./styled";

export const Landing: React.FunctionComponent = () => {
  const navigate = useNavigate();

  const handleOnClick = () => {
    navigate(-1);
  };

  useEffect(() => {
    // 直前のページにリダイレクトする
    navigate(-1);
  }, [navigate]);

  return (
    <Styled.Container>
      <Header />
      <Spacer pt={1} />
      <Spacer p={2}>
        <Typography lineHeight="1.7" size="xxl">
          このページはインターステイシャル広告の動作確認用のページです。
        </Typography>
        <Typography lineHeight="1.7" size="xxl">
          自動でプレビューページに戻らない場合は、以下のボタンで遷移してください。
        </Typography>
      </Spacer>
      <Spacer p={5}>
        <Styled.LinkButtonContainer>
          <Button inline size="large" onClick={handleOnClick}>
            プレビューに戻る
          </Button>
        </Styled.LinkButtonContainer>
      </Spacer>
    </Styled.Container>
  );
};
