import React, { useEffect, useState } from "react";
import * as Styled from "./styled";
import {
  TextField,
  ConfirmModal,
  Typography,
  Spacer,
  Badge,
  ActionButton,
} from "ingred-ui";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-html";
import "ace-builds/src-noconflict/theme-github";
import { StorageKey } from "../../../../constants/storageKeys";
import { Tag } from "../../../../types";

type Props = {
  tag: Tag;
  onClose: () => void;
  onSubmit: ({
    title,
    body,
    memo,
    tagId,
  }: Pick<Tag, "title" | "body" | "memo" | "tagId">) => void;
  loading: boolean;
};

const RESPONSIVE_BOUNDARY = 1300;

const EditModal: React.FunctionComponent<Props> = ({
  tag,
  onClose,
  onSubmit,
  loading,
}) => {
  const [width, setWidth] = useState<number>(window.innerWidth);
  const [title, setTitle] = useState<Tag["title"]>(tag.title);
  const [body, setBody] = useState<Tag["body"]>(tag.body);
  const [memo, setMemo] = useState<Tag["memo"]>(tag.memo);

  // リサイズイベントを作成
  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
  });

  const isFullSize = (w: number) => {
    return w > RESPONSIVE_BOUNDARY;
  };

  const onHandleSubmit = (event: React.FormEvent) => {
    const tagId = tag.tagId;
    event.preventDefault();
    onSubmit({ title, body, memo, tagId });
  };

  const onHandleOpenPreview = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.preventDefault();
    localStorage.setItem(StorageKey.TAG_BODY_BUFFER, body);
    window.open("/preview");
  };

  const onHandleOpenAMPPreview = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.preventDefault();
    localStorage.setItem(StorageKey.TAG_BODY_BUFFER, body);
    window.open("/ampPreview");
  };

  const handleTitleUpdate = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
  };

  return (
    <ConfirmModal
      fullSize={isFullSize(width)}
      title="タグの編集"
      confirmText="保存する"
      buttonColor="primary"
      disabled={!title || !body}
      loading={loading}
      onClose={onClose}
      onSubmit={onHandleSubmit}
    >
      <Styled.FormContainer>
        <Styled.FormGroup>
          <Styled.FormGroupLeft>
            <Typography weight="bold">タイトル</Typography>
            <Spacer pr={1} />
            <Badge color="danger">必須</Badge>
          </Styled.FormGroupLeft>
          <Styled.FormGroupRight>
            <Styled.TextFieldContainer>
              <TextField
                name="title"
                value={title}
                onChange={handleTitleUpdate}
              />
            </Styled.TextFieldContainer>
          </Styled.FormGroupRight>
        </Styled.FormGroup>
        <Styled.ContentContainer>
          <Styled.EditorContainer>
            <Spacer pb={0.8}>
              <Styled.ContentContainer>
                <Styled.FormGroupLeft>
                  <Typography weight="bold">広告タグ</Typography>
                  <Spacer pr={1} />
                  <Badge color="danger">必須</Badge>
                </Styled.FormGroupLeft>
                <Styled.PreviewButtonContainer>
                  <Spacer pl={1} />
                  <ActionButton
                    icon="external_link"
                    onClick={onHandleOpenPreview}
                  >
                    プレビューを表示
                  </ActionButton>
                  <Spacer pl={1} />
                  <ActionButton
                    icon="external_link"
                    onClick={onHandleOpenAMPPreview}
                  >
                    AMPタグ用プレビューを表示
                  </ActionButton>
                </Styled.PreviewButtonContainer>
              </Styled.ContentContainer>
            </Spacer>
            <Styled.EditorBodyContainer>
              <AceEditor
                value={body}
                wrapEnabled={true}
                showPrintMargin={false}
                mode="html"
                theme="github"
                name="tag-source"
                width="100%"
                editorProps={{ $blockScrolling: true }}
                onChange={(value) => setBody(value)}
              />
            </Styled.EditorBodyContainer>
          </Styled.EditorContainer>
          <Spacer pr={2} />
          <Styled.EditorContainer>
            <Spacer pb={1}>
              <Spacer p={1.3}>
                <Typography weight="bold">メモ</Typography>
              </Spacer>
            </Spacer>
            <Styled.EditorBodyContainer>
              <AceEditor
                value={memo}
                wrapEnabled={true}
                showPrintMargin={false}
                mode="markdown"
                theme="github"
                name="memo"
                width="100%"
                editorProps={{ $blockScrolling: true }}
                onChange={(value) => setMemo(value)}
              />
            </Styled.EditorBodyContainer>
          </Styled.EditorContainer>
        </Styled.ContentContainer>
      </Styled.FormContainer>
      <Spacer p={2}>
        <Styled.ExplanationContainer>
          <Typography component="li" lineHeight="1.7" size="xxl">
            広告タグ欄にはタグスクリプト本体のみを貼り付けてください。
          </Typography>
          <Typography component="li" lineHeight="1.7" size="xxl">
            タグを共有するクライアントへの説明などを添えたい場合はメモ欄に記入してください。
          </Typography>
          <Typography component="li" lineHeight="1.7" size="xxl">
            貼り付けたタグの表示を確認したい場合は「プレビューを表示」
            ボタンをクリックしてください。タグのプレビュー画面が別タブで表示されます。
          </Typography>
        </Styled.ExplanationContainer>
      </Spacer>
    </ConfirmModal>
  );
};

export { EditModal };
