import * as React from "react";
import { Tag } from "../../../../types";
import { Typography, ConfirmModal, Spacer } from "ingred-ui";

type Props = {
  tag: Tag;
  onClose?: () => void;
  onSubmit?: (demandId: string) => void;
};

const DeleteModal: React.FunctionComponent<Props> = ({
  tag,
  onClose,
  onSubmit,
}) => {
  const onHandleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (onSubmit) {
      onSubmit(tag.tagId);
    }
  };
  return (
    <ConfirmModal
      title="タグの削除"
      confirmText="削除する"
      buttonColor="danger"
      onClose={onClose}
      onSubmit={onHandleSubmit}
    >
      <Spacer pt={2}>
        <Typography lineHeight="1.7">
          {tag.title}を削除してもよろしいですか？
        </Typography>
      </Spacer>
    </ConfirmModal>
  );
};

export { DeleteModal };
