import styled from "styled-components";

export const Container = styled.div``;

export const ButtonContainer = styled.div`
  background: ${({ theme }) => theme.palette.white};
  height: 600px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
