import React from "react";
import { Spinner } from "ingred-ui";
import * as Styled from "./styled";

export const Loading: React.FC = () => {
  return (
    <Styled.Container>
      <Spinner />
    </Styled.Container>
  );
};
