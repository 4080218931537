import { db } from "./firebase";
import firebase from "firebase/app";

const TAGS = "tags";
const AUTHORS = "authors";

export const getTag = (tagId: string) => db.collection(TAGS).doc(tagId).get();

export const getTagsByUser = (
  userId: string,
  sortKey = "updated",
  sortOrder: "asc" | "desc" = "desc",
  per = 10,
  cursor?: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>,
) => {
  const ref = db
    .collection(TAGS)
    .where("authorRef", "==", db.collection(AUTHORS).doc(userId))
    .orderBy(sortKey, sortOrder);

  if (cursor) {
    return ref.startAfter(cursor).limit(per).get();
  } else {
    return ref.limit(per).get();
  }
};

export const getAllTags = () => db.collection(TAGS).get();

export const getTagsByParams = (
  sortKey = "updated",
  sortOrder: "asc" | "desc" = "desc",
  per = 10,
  cursor?: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>,
) => {
  const ref = db.collection(TAGS).orderBy(sortKey, sortOrder);
  if (cursor) {
    return ref.startAfter(cursor).limit(per).get();
  } else {
    return ref.limit(per).get();
  }
};

export const createTag = (
  body: string,
  title: string,
  memo: string,
  userId: string | undefined,
) =>
  db.collection(TAGS).add({
    body,
    title,
    memo,
    created: firebase.firestore.FieldValue.serverTimestamp(),
    updated: firebase.firestore.FieldValue.serverTimestamp(),

    // reference型でいれる
    authorRef: db.collection(AUTHORS).doc(userId),
  });

export const updateTag = (
  title: string,
  body: string,
  memo: string,
  tagId: string,
) =>
  db.collection(TAGS).doc(tagId).update({
    title,
    body,
    memo,
    updated: firebase.firestore.FieldValue.serverTimestamp(),
  });

export const deleteTag = (tagId: string) =>
  db.collection(TAGS).doc(tagId).delete();

export const getAuthor = (userId: string) =>
  db.collection(AUTHORS).doc(userId).get();

export const addAuthor = (
  userId: string, // Firebase AuthenticationのユーザID
  email: string | null,
  name: string | null,
) =>
  db.collection(AUTHORS).doc(userId).set({
    email,
    name,
    created: firebase.firestore.FieldValue.serverTimestamp(),
  });
